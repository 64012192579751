*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.container{
    display:flex;
}
main{
    width: 100%;
    
}
.sidebar{
    background: #fff;
    color: #273053;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
    border-bottom-width:10px;
    border-bottom-color: #000;
}
.logo{
    font-size: 30px;
  
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.link{
    display: flex;
    color: #c8cfe3;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    
    color: #000;
    transition: all 0.5s;
}
.active{
    
    color: #000;
}
.icon, .link_text{
    font-size: 20px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


